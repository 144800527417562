import React from 'react';

import {XStack, View, Image} from '@medad-sep/core';

import {LanguageSelector} from './LanguageSelector';
import {Notifications} from './Notifications';
import {UserInfo} from './UserInfo';

export function AppTopBar(): JSX.Element {
  return (
    <XStack
      paddingBottom="$4"
      paddingHorizontal="$12"
      backgroundColor="$background"
      justifyContent="space-between"
      alignItems="center"
      borderBottomColor="$border"
      borderBottomWidth="$0.5"
      overflow="scroll"
      height="7vh">
      <View width="$12">
        <Image objectFit="contain" source={{uri: '/logo.png', height: 40}} />
      </View>

      <XStack alignItems="center" justifyContent="flex-end" gap="$16" width="$12">
        <Notifications />
        <LanguageSelector />
        <UserInfo />
      </XStack>
    </XStack>
  );
}

AppTopBar.defaultProps = {
  menuItems: [],
};
