import React, {Suspense} from 'react';

import {View, XStack, YStack} from '@medad-sep/core';
import {AppTopBar} from '@shared/components/AppTopBar';
import {PageFallback} from '@shared/components/PageFallback';
import {useCurrentUserConfig} from '@shared/hooks/ui/useCurrentUserConfig';

import {AppSideBar} from '../AppSideBar';

interface Props {
  children: any;
}

export function AppSection({children}: Props) {
  const {menuItems} = useCurrentUserConfig();

  return (
    <View height="100vh">
      <AppTopBar />
      <XStack flex={1}>
        <AppSideBar menuItems={menuItems} />
        <YStack flex={1}>
          <View>
            <Suspense fallback={<PageFallback />}>{children}</Suspense>
          </View>
        </YStack>
      </XStack>
    </View>
  );
}
